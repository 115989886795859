(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/marketplace-brand/assets/marketplace-brand.js') >= 0) return;  svs.modules.push('/components/marketplace/marketplace-brand/assets/marketplace-brand.js');
"use strict";

const _excluded = ["productId", "subProductId"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }

const uiMockable = svs.ui;
const {
  useSelector
} = ReactRedux;
const {
  getProductBrand
} = svs.components.lbUtils.brandMapping.fn;
const {
  EUROPATIPSET
} = svs.utils.products.productIds;
const MarketplaceBrand = _ref => {
  let {
      productId,
      subProductId = null
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const extratipset = useSelector(state => state.extratipset);
  let subProductIdBrand = null;
  if (productId === EUROPATIPSET) {
    subProductIdBrand = subProductId || subProductId === null && extratipset;
  }
  const brand = getProductBrand(productId, subProductIdBrand);
  if (brand) {
    return React.createElement(uiMockable.ReactBrand, _extends({
      brand: brand
    }, props));
  }
  return null;
};
setGlobal('svs.components.marketplace.marketplaceBrand.MarketplaceBrand', MarketplaceBrand);

 })(window);